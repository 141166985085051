import React from "react"
import './Subscribe.scss'

export default function Subscribe() {
    return <div className="container">
      <div className="subscribe">

        <h2>Want to see more future projects?</h2>
        <h3>Sign up to be notifed of new projects!</h3>

        <form action="https://getform.io/f/02f4592b-6d69-488f-908c-df76191869b9" method="POST">
          <input type="email" name="email" placeholder="Enter your email" className="required email" required />
          <input type="hidden" name="_gotcha" style={{ display: "none !important" }} />
          <input type="submit" className="button" value={'Notify Me!'}/>
        </form>

      </div>
    </div>
}