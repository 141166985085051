import React from "react"
import SearchEngineOptimisation from "../components/SearchEngineOptimisation"
import Hero from '../components/Hero'
import Subscribe from '../components/Subscribe'
import Footer from '../components/Footer'
import '../styles/styles.scss'

const SITES = [
  {
    id: 1,
    title: "How Much Money Is That",
    image: "/sites/HowMuchMoneyIsThat.webp",
    url: "https://human.games/how-much-money-is-that"
  },
  {
    id: 2,
    title: "Plant Based Calculator",
    image: "/sites/PlantBasedCalculator.webp",
    url: "https://human.games/plant-based-calculator"
  },
  {
    id: 3,
    title: "Spend Their Money",
    image: "/sites/SpendTheirMoney.webp",
    url: "https://human.games/spend-their-money"
  },
  {
    id: 4,
    title: "How Much Data Is That",
    image: "/sites/HowMuchDataIsThat.webp",
    url: "https://majestic-scone-cd14ce.netlify.app/"
  }
]


export default function Home({ data }) {
  return <div>
    <SearchEngineOptimisation 
      keywords={"human3x human apps fun games developer node javascript react"}
      schemaMarkup={
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Human Games",
          "url": "https://human.games/",
          "description": "A space showcasing projects and apps created with Javascript, React, and Python."
      }
    } />
    <Hero />

    <div className="container">
      <div className="works">
        {SITES.map(work => (
          <a href={work.url} key={work.id} className="work">
            {/* TODO image could do with optimising, i didn't specify a exact size or anything */}
            <img
              src={work.image}
              alt={work.title}
            />
          </a>
        ))}
        <div className="work non-clickable">
          <p>More coming soon...</p>
        </div>
      </div>
    </div>

    <Subscribe />

    <div className="container">
      <div className="button share">
        <a href="https://www.facebook.com/HumanGamesUK/"><img src="/fb.png" alt="facebook" />Facebook</a>
      </div>
      <div className="button share">
        <a href="https://twitter.com/humangamesuk"><img src="/twt.svg" alt="twitter" />Twitter</a>
      </div>
    </div>

    <Footer />
  </div>
}